

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/engager-services-representation-avocat-conseils-sherbrooke.jpg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const ContentPage17 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Pourquoi engager les meilleurs avocats à Sherbrooke pour s’occuper de vos disputes légales? - Soumissions Avocat"
        description="Il n’est pas toujours facile d’accepter la gravité de sa propre situation. Le déni étant une force puissante, il n’est pas étonnant qu’autant de gens se retrouvant dans un branle-bas de combat juridique attendent trop longtemps avant de contacter un avocat. Penser que le problème disparaîtra de lui-même ou que LIRE PLUS"
        image={LeadImage}>
        <h1>Pourquoi engager les meilleurs avocats à Sherbrooke pour s’occuper de vos disputes légales?</h1><p>Il n’est pas toujours facile d’accepter la gravité de sa propre situation. Le déni étant une force puissante, il n’est pas étonnant qu’autant de gens se retrouvant dans un branle-bas de combat juridique attendent trop longtemps avant de contacter un avocat. Penser que le problème disparaîtra de lui-même ou que la personne qui vous poursuit changera tout bonnement d’idée vous laisse vulnérable, c’est le moins qu’on puisse dire.</p>
<p><StaticImage alt="engager-services-representation-avocat-conseils-sherbrooke" src="../images/engager-services-representation-avocat-conseils-sherbrooke.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Au lieu de négliger vos propres intérêts au péril de devoir payer des milliers de dollars en dommages-intérêts, prenez votre courage à deux mains et demandez l’aide un avocat qualifié de la ville de Sherbrooke. Pour vous aider dans votre démarche de recherche, nous avons répertorié les aspects essentiels de la pratique du droit en Estrie que vous vous devez de connaître!</p>
<h2>Les avantages de consulter un avocat pour un régler un litige</h2>
<p>Pour vous convaincre des compétences tout à fait indispensables de l’avocat à Sherbrooke, faire la liste des avantages de faire appel à ses services est un bon point de départ. Non seulement celui-ci est-il en mesure de s’occuper de votre litige, mais ses connaissances légales et son expérience de pratique vous faciliteront nettement la vie, cela est certain!</p>
<p><strong>Rapidité à régler le dossier.</strong> Bien que votre dossier soit unique en son genre, toujours est-il que les avocats traitent de nombreuses situations similaires à la vôtre. De ce fait, ils sont en mesure de rapidement élaborer des stratégies efficaces pour mettre un terme à la dispute. Ils trouveront soit une entente ou ils parviendront à persuader la partie adverse de la futilité de leur poursuite.</p>
<p><strong>Efficacité de l’expertise de l’avocat.</strong> Évidemment, vous pouvez (et devriez) toujours tenter de régler votre différend à l’amiable avant de contacter un avocat. Discuter civilement avec la personne avec qui vous éprouvez des divergences d’opinions. Toutefois, à un certain point et lorsque vos tentatives échouent, il est temps d’engager un avocat qui saura user de ses connaissances légales pour persuader votre adversaire. C’est leur travail après tout, alors laisser les commandes à un avocat!</p>
<p><strong>Économies ou gains monétaires potentiels.</strong> Lors d’une poursuite civile, la principale et souvent l’unique exigence du demandeur sont le versement de dommages-intérêts. De ce fait, que vous soyez de l’un côté ou l’autre de la poursuite, vous avez avantage à engager un avocat! Ce dernier vous sortira d’impasse et vous éviterez de devoir payer des dommages en tant que défendeur. Du côté de la poursuite, demander l’aide d’un avocat augmente vos chances de victoire en tant que demandeur, ce qui se traduira en somme monétaire accordée!</p>
<p><strong>Faire respecter vos droits fondamentaux!</strong> Comme on dit, il n’y a pas que l’argent dans la vie! Pour cette raison, le simple fait de faire valoir vos droits en cour par un avocat qualifié et réputé est un avantage incomparable!</p>
<h2>Le déroulement d’un procès civil : le jeu en vaut-il toujours la chandelle?</h2>
<p>Pourquoi devez-vous connaître étapes principales d’un procès alors que vous payez un avocat justement pour s’occuper de ces formalités? Parce qu’en apprenant ne serait-ce qu’un peu sur l’envers du décor, vous comprendrez les efforts qui doivent être consacrés pour mener à bien une telle démarche. Cela pourrait même vous faire reconsidérer votre volonté de poursuivre, qui sait?</p>
<p><StaticImage alt="deroulement-etapes-proces-civil-avocat-sherbrooke" src="../images/deroulement-etapes-proces-civil-avocat-sherbrooke.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Mise en demeure :</strong> Recevoir une mise en demeure n’a pas de grande valeur légale. En fait, il s’agit d’une façon de demander à quelqu’un de cesser le trouble allégué ou de remédier à son inaction vis-à-vis une obligation d’agir.  Toutefois, comme elle est envoyée d’une personne à l’autre par l’entremise d’un avocat, elle n’a aucun pouvoir de sanction. Toutefois, la mise en demeure est exigée dans certains types de causes portées devant les tribunaux et devient pertinente dans l’attribution des frais de justice.</p>
<p><strong>Demande introductive d’instance :</strong> Pour passer de la parole aux actes (et assumant que la mise en demeure n’ait rien changé au comportement fautif du futur défendeur), il sera temps d’envoyer une demande introductive d’instance. Cette requête se fait auprès du tribunal compétent à entendre votre cause. Comment savoir quel tribunal a compétence? En connaissant les dispositions du Code de procédure civile que seuls les avocats se donnent la peine de lire!</p>
<p>Ainsi, ce sera fort probablement votre avocat qui déposera la demande auprès de la cour et, par la même occasion, une assignation à comparaître sera envoyée au défendeur que vous poursuivez.</p>
<p>Protocole de l’instance : Il est maintenant temps de passer au protocole de l’instance. Comme dans toutes les guerres, des règles entourent le déroulement du conflit pour assurer un minimum de respect et de décence lors des hostilités. C’est ce que vise le protocole de l’instance : à établir la façon dont les partis échangeront les informations essentielles, les échéanciers que chacun doit respecter au niveau de la production de documents et les dispositions sur les mesures de sauvegarde.</p>
<p><strong>Défense :</strong> L’étape de la défense est celle lors de laquelle le défendeur expose les motifs factuels et légaux qui justifient sa contestation de la poursuite. Il est possible pour lui de nuancer les accusations portées contre lui, voire même des les mitiger, ou encore de simplement les nier complètement.</p>
<p><strong>Conférence de règlement à l’amiable :</strong> Peu importe l’étape franchie dans le déroulement du procès, il est toujours possible de demander une telle conférence. Le juge attitré à la cause présidera cette conférence et tentera de guider les partis vers une entente commune. Cette rencontre a un caractère informel, bien qu’elle soit tenue huis clos et qu’elle soit strictement confidentielle.</p>
<p><strong>Audience :</strong> À moins d’un règlement à l’amiable, l’audience prendra place devant un juge seul qui décidera par lui-même de quel côté trancher. Le demandeur exposera d’abord les faits qui supportent ses prétentions ainsi que les dispositions légales qui lui donnent supposément raison. En revanche, la défense tentera par tous les moyens de balayer du revers de la main les arguments du demandeur.</p>
<p><strong>Jugement :</strong> Armez-vous de patience, car rendre un jugement peut prendre des mois! Le juge entendra la cause et la prendra en délibéré, ce qui signifie qu’il prendra le temps d’analyser les preuves soumises ainsi que le droit applicable. Si les délais sont longs, dites-vous que c’est parce que le droit est une machine fort complexe, même pour un juge!</p>
<p><strong>Toutes ces étapes en valent-elles la peine? </strong>Il s’agit là d’une question bien personnelle, mais à laquelle vous devrez avoir une réponse avant de vous engager dans ce processus légal. Comme les frais d’avocat et le temps personnel investi dans un procès sont considérables, vous devez être certain que le jeu en vaut la chandelle. Vous seul pouvez répondre à cette question toutefois!</p>
<h2>Quel genre de preuve est admissible en cour?</h2>
<p>En pensant à votre futur procès, surement que vous vous imaginiez déjà en train de convoquer le village tout entier à témoigner en votre faveur ou encore vous pensez à présenter une pile astronomique de documents en guise de preuve. Ce que vous ignorez, c’est que le droit de la preuve civile est un secteur du droit qui encadre strictement les éléments de preuve admissibles en cour. Évidemment, ce ne sont pas tous les objets et tous documents qui ont la même valeur ou qui sont autorisés! Nous vous présentons donc les bases en ce qui a trait à l’admissibilité des preuves.</p>
<p><StaticImage alt="droit-civil-preuve-admissible-cour-temoins-ecrits" src="../images/droit-civil-preuve-admissible-cour-temoins-ecrits.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>
Les témoignages : </strong>Utiliser des témoins est un moyen fort utile de bâtir une preuve et un argumentaire solide qui supporte votre cause. Toutefois, des limites s’imposent. La règle générale veut que les témoins soient admissibles pour prouver des faits juridiques, c’est-à-dire, des évènements qui pourraient avoir une conséquence juridique sur les partis.</p>
<p>En revanche, les témoins sont restreints quant à leur capacité de contester des actes juridiques en cour (ex : des contrats). Ils pourront contester des actes juridiques notamment lorsqu’il y aura un commencement de preuve supportant les propos qu’ils s’apprêtent à alléguer, à condition que la valeur de l’acte en question ne dépasse pas les 1500$.</p>
<p>Mais quelle est la valeur du témoignage? Ce sera au juge de décider! On dit de la force probante d’un témoignage est laissée à la libre appréciation du juge. Il est donc le maître incontesté chargé de juger de la crédibilité des témoins et de la véracité de leurs propos.</p>
<p><strong>Les actes par écrit : </strong>Les écrits sont une façon solide de prouver une prétention quelconque. Cela est surtout vrai pour prouver l’existence d’un contrat entre deux personnes. Il faut toutefois se garder de comparer tous les écrits de la même façon; certains ont une plus grande valeur que d’autres.</p>
<ul>
<li>Actes authentiques : On dit des actes authentiques qu’ils font preuve de leur contenu. Il faut qu’un officier public ait entrepris la rédaction ou la validation d’un tel document pour qu’il soit considéré comme authentique. Un exemple notoire est le testament notarié. En vertu des pouvoirs du notaire en tant qu’officier public, le contenu du testament est une preuve en soi qui ne demande pas de vérifications additionnelles.</li>
<li>Actes semi-authentiques : L’acte semi-authentique est la même chose qu’un acte authentique, à la différence près qu’il provient d’un pays étranger. Ils ont la même valeur que les actes authentiques excepté lorsqu’ils font l’objet d’une contestation. 
</li>
<li>Actes sous seing privé : De tels documents sont ceux qui ne font que porter les signatures de partis impliqués, sans qu’elles ou le contenu du document n’aient été vérifiés par quelconque autorité. Ainsi, leur contenu doit toujours être prouvé.</li>
</ul>
<p><strong>Ouï-dire :</strong> Parfois difficile à concevoir, le Ouï-dire est une forme de témoignage par proxy en ce sens qu’il consiste à rapporter les faits vécus par une autre personne dont un tiers a connaissance. Il est donc admissible en cour, mais sous toute réserve de la valeur y étant accordée par le juge.</p>
<p><strong>La présomption : </strong>En cour, il est possible de tirer des conclusions à partir de simples présomptions. Le droit différencie les présomptions de fait à celles légales. Ces dernières sont celles que la loi prévoit expressément et qui sont dites « absolues ». Lorsqu’elles sont factuelles, les présomptions permettent de passer d’un fait établi à un fait inconnu. Le parti alléguant cette présomption devra évidemment présenter les faits de manière à supporter cette présomption pour ensuite laisser au juge le soin d’analyser le bien-fondé de celle-ci.</p>
<p><strong>L’aveu :</strong> Simple à définir, l’aveu consiste à admettre les faits dans le but de produire un effet juridique. On différencie deux types d’aveux en droit civil québécois, soit l’aveu judiciaire et l’aveu extrajudiciaire. Ce premier consiste en fait à admettre, en cour, les faits qui ont été allégués. En revanche, l’aveu extrajudiciaire consiste plutôt à avouer en dehors du tribunal les faits en question. La forme peut être écrite ou verbale, mais celle-ci est plus difficile à présenter.</p>
<p><strong>L’élément matériel :</strong> On parle ici d’un objet supportant la thèse du demandeur ou du défendeur. Quels sont les objets admissibles? Comme le Code civil du Québec stipule que la preuve peut être faite « par tous les moyens », du moment qu’elle est pertinente et qu’elle aide au règlement du litige, un large éventail d’objets peut être présenté en guise de preuve.</p>
<p><strong>Les règles de preuve sont parfois complexes pour les inhabitués des procès civils, mais pour les avocats œuvrant dans le domaine, il s’agit d’une partie fondamentale de leur travail. Ils se feront donc un plaisir d’analyser vos preuves potentielles avec vous!</strong></p>
<h2>Quels sont les services de l’avocat en droit immobilier à Sherbrooke?</h2>
<p>Le droit immobilier figure certainement parmi les secteurs juridiques les plus diversifiés. Comme les biens immobiliers figurent au cœur du moteur économique, il n’est pas étonnant qu’autant d’avocats offrent des services connexes dans la région de Sherbrooke et en Estrie. Pour vous en faire la preuve, voici les services avec lesquels un avocat en droit immobilier pourra vous aider!</p>
<p><strong>Droit de la copropriété : </strong> Les immeubles en copropriété comme les condominiums ont certes la cote depuis maintenant plusieurs années, mais ils sont également des lieux propices à conflits. Comme le droit de propriété de chaque propriétaire cohabite de près avec celui des autres, les litiges sont propices à survenir. Cela peut être causé par un désaccord concernant la déclaration de copropriété, les travaux effectués sur l’immeuble, les décisions du syndicat et plus encore!<strong>
</strong>
<strong>Acquisition et vente d’immeubles : </strong>Si vendre une maison ne nécessite pas l’intervention d’un avocat, la situation change lorsqu’on vend un immeuble à revenus. La valeur supérieure de l’immeuble justifie l’aide d’un avocat afin d’assurer une transaction sécuritaire, mais également pour que toutes les vérifications diligentes nécessaires aient été réalisées. Cela peut s’avérer pertinent autant dans le cadre de l’exploitation d’une entreprise qu’entre particuliers.<strong>
</strong>
<strong>Servitudes et droits de passage : </strong>La servitude se qualifie comme une « charge » imposée sur un fonds par un autre fonds. Celle-ci confère le droit au propriétaire du fonds dominant de faire usage de l’assiette de servitude selon sa destination pendant la durée prévue. Il n’est pas rare que de tels démembrements du droit de propriété mènent à des conflits sur l’étendue du droit conféré par la servitude ou encore sur l’existence même de la servitude. Les avocats sont justement là pour mettre de l’ordre dans cette confusion!
<strong>
Non-respect d’un contrat : </strong>L’immobilier est un domaine qui utilise fréquemment le mécanisme de la promesse d’achat, qui consiste en fait en un avant-contrat conclu en vue de signer l’acte de vente imminemment. Il arrive toutefois que des acheteurs ou des vendeurs ne comprennent pas le caractère contraignant d’une telle promesse, décidant ainsi de ne pas y donner suite.</p>
<p>Toutefois, la promesse d’achat est un contrat comme n’importe quel autre malgré les synonymes qu’on peut lui donner, comme la « promesse », « l’offre d’achat » ou « l’avant-contrat ». Ainsi, une partie contractante qui refuse de donner suite à la promesse s’expose à des recours légaux, notamment la passation de titres forcée,  qu’il s’agisse du vendeur ou de l’acheteur.</p>
<h2>Prescription acquisitive: l’importance d’engager un avocat à Sherbrooke</h2>
<p>Le principe de la prescription acquisitive peut sembler difficile à concevoir pour le commun des mortels, puisqu’il permet d’usurper le droit de propriété d’un autre après un délai de possession suffisant. En effet, la personne qui agit à titre de possesseur d’un bien immobilier pendant une période de 10 ans peut acquérir par prescription acquisitive le terrain qu’il possédait.</p>
<p><StaticImage alt="Le fonctionnement de la prescription acquisitive à Sherbrooke" src="../images/prescription-acquisitive-sherbrooke-avocat-vice-cache.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Encore faut-il que la personne se qualifie à titre de possesseur! </strong>Un individu se qualifiera comme tel lorsqu’il aura la possession matérielle du bien et qu’il se présentera aux yeux des autres comme le véritable propriétaire du bien. Concrètement, cela peut prendre la forme d’une personne qui entretient un terrain qui ne lui appartient pas pendant une durée de 10 ans et qui agit comme si la parcelle lui appartenait réellement.</p>
<p>Attention! Pour que la personne demeure en possession, il faut que le délai reste interrompu, c’est-à-dire, que le véritable ne vienne pas la déposséder en entretenant ou en utilisant elle-même le terrain en question.</p>
<p><strong>Quels sont les effets de la possession? </strong>Une possession conforme au droit et maintenue sans interruption à l’égard d’un bien immobilier pour une période de 10 ans permet d’invoquer les effets de la prescription acquisitive, c’est-à-dire, de réclamer le droit de propriété conforme au titre.</p>
<p>Cela paraît difficile à croire, mais c’est une situation qui survient fréquemment dans les régions campagnardes et agricoles comme l’Estrie où les lignes mitoyennes entre les terrains ne sont pas toujours définies clairement.</p>
<h2>Recours pour vice caché avec un avocat de Sherbrooke</h2>
<p>L’achat d’une maison ou d’un immeuble représente l’investissement de centaines de milliers de dollars. Lorsque le bien acheté ne possède pas les qualités qu’on vous a vendues ou, pire encore, qu’il présente un vice qui affecte substantiellement sa valeur, il s’agit potentiellement d’un vice caché et il est important d’agir dans les plus brefs délais en contactant un avocat spécialisé en droit immobilier à Sherbrooke!</p>
<p><strong>Quelles sont les conditions d’un recours pour vice caché? </strong>La définition d’un vice caché est sujette à débat et c’est la raison pour laquelle il existe une jurisprudence abondante sur le sujet. Cependant, le vice doit respecter les critères du vice grave, non apparent, antérieur à la vente, et inconnu de l’acheteur au moment d’acheter la propriété.</p>
<p><strong>Pourquoi est-il important de consulter un avocat rapidement? </strong>Premièrement parce que la victime du vice a l’obligation de mitiger les dommages en agissant rapidement et éviter que son omission d’agir n’aggrave le problème, mais également pour maximiser les chances de prouver le vice. De plus, la loi exige que l’acheteur avise le vendeur du vice caché dans un délai raisonnable et par écrit; il vaut donc mieux agir rapidement pour éviter de compliquer le recours.</p>
<p><strong>La vente sans garantie légale vous prive-t-elle de tout recours?</strong> La raison pour laquelle les gens vendent leur maison sans garantie légale de qualité est qu’ils savent pertinemment qu’elle est susceptible de contenir des défauts de toutes sortes. Le prix réduit reflète généralement le risque qu’encourt l’acheteur et il devient donc effectivement impossible ou du moins extrêmement difficile de poursuivre pour un vice caché lors d’une telle transaction.</p>
<p>Cependant, une vente sans garantie légale ne donne pas le droit au vendeur de mentir ou de cacher des informations à l’acheteur. Cela constituerait des manœuvres dolosives qui vicieraient le consentement de l’acheteur, même si la vente se fait sans garantie légale.</p>
<p><strong>Quelles sont les solutions possibles lors de la découverte d’un vice caché?</strong> Le litige est loin d’être la seule solution envisageable en présence d’un vice caché. Les avocats œuvrant en droit immobilier sont en mesure de négocier des règlements hors cour entre l’ancien propriétaire et le nouvel acheteur afin de trouver un terrain d’entente.</p>
<p><strong>Si vous avez découvert un vice caché dans votre propriété à Sherbrooke, ne tardez pas avant de contacter un avocat en droit immobilier, faites plutôt confiance à Soumissions Avocat!</strong></p>
<h2>Quelles sont les principales qualités d’un bon avocat?</h2>
<p>Engager un avocat demande un certain travail de recherche, c’est inévitable. Vous ne pourrez pas tomber sur la perle rare comme par hasard simplement en choisissant la première option offerte. En comparant les experts, vous devriez garder un œil attentif sur ces qualités qui font un bon avocat.</p>
<p><strong>La communication : </strong>Sous toutes ses formes, la communication entre un client et son avocat est indispensable. Les informations échangées ont un caractère si confidentiel que la mauvaise communication ne fera que rendre le processus pénible pour tous.</p>
<p><strong>L’audace :</strong> On dit que la réputation d’un avocat commence à reluire le jour où il se met à gagner des causes perdantes. Pour cela, votre avocat doit avoir le courage d’essayer des nouvelles techniques et des nouvelles approches de droit.</p>
<p><StaticImage alt="qualites-bon-avocat-argumentation-communication-assiduite-sherbrooke" src="../images/qualites-bon-avocat-argumentation-communication-assiduite-sherbrooke.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>L’argumentation : </strong>Les avocats gagnent leur vie à débattre. Il est donc normal que vous deviez rechercher des juristes ayant une capacité à argumenter hors du commun. Les arguments de votre avocat deviendront les vôtres, alors mettez cet aspect en priorité!</p>
<p><strong>L’assiduité :</strong> Travailler en tant qu’avocat exige de nombreuses heures de travail, de recherche, de rédaction et de contact avec les différents acteurs impliqués. Un avocat qui ne possède pas une telle assiduité n’est pas fait pour vous!</p>
<p><strong>La patience : </strong> Personne de pressé ne trouve son compte dans la sphère légale. Les délais sont nombreux et souvent longs! Il faut donc que votre avocat ait la patience d’amener votre litige jusqu’à bon port coute que coute!</p>
<h2>Les questions fréquentes pour les avocats de Sherbrooke</h2>
<p>La meilleure façon de vous préparer à affronter la tempête juridique qui vous menace à l’horizon, c’est d’être en plein contrôle des faits et des lois applicables à votre litige. Comment faire si vous n’avez jamais suivi de formation légale? En posant des questions à votre avocat, bien sûr!</p>
<p><strong>Votre avocat a-t-il déjà pris en charge un dossier similaire au vôtre?</strong></p>
<p>L’expérience ne s’achète pas et s’il y a bien une chose qui facilitera le règlement de votre dossier, c’est l’historique de votre avocat en matière de pratique légale. Si celui-ci a déjà réglé plusieurs dossiers comme le vôtre, il saura où donner de la tête pour clore le tout le plus efficacement et aussi rapidement que possible.</p>
<p><strong>Comment votre avocat évalue-t-il vos chances d’obtenir gain de cause?</strong></p>
<p>La victoire est l’objectif de votre démarche auprès d’un avocat. Il serait illogique de ne pas vous enquérir auprès de lui de vos chances de l’emporter n’est-ce pas? Soyez conscients que sa réponse ne sera qu’une prédiction basée sur ses années d’expérience et son savoir juridique et que le droit n’est pas une science exacte; il peut se tromper. Toutefois, poser la question ne fera que vous donner une idée plus précise du dénouement auquel vous devriez vous attendre.</p>
<p><strong>Quel est le fonctionnement de sa facturation et combien d’heures pense-t-il consacrer au dossier?</strong></p>
<p>Fonctionne-t-il selon un taux horaire, un montant forfaitaire, un pourcentage des dommages obtenus ou selon une autre méthode de facturation propre à lui? Exige-t-il le versement d’une avance en fidéicommis? Ce sont là des questions auxquelles les réponses ont une incidence directe sur votre portefeuille. Il est donc crucial de les poser dès le départ.</p>
<p><strong>Est-ce que votre avocat envisage la possibilité de régler la dispute hors cour?</strong></p>
<p>Votre philosophie et celle de votre avocat ne doivent faire qu’un. Il est impératif que vous vous mettiez sur la même longueur d’onde dès le départ sur la possibilité de régler le dossier autrement qu’en cour. Discutez donc avec lui des méthodes alternatives de règlement de différend, telles que la médiation ou l’arbitrage.<strong>
</strong></p>
<p><strong>Pourquoi l’avocat croit-il être la meilleure personne pour vous aider à gagner votre cause?</strong></p>
<p>Les avocats se font sans cesse lancer des fleurs pour leurs prétendus talents d’orateurs et de vendeurs. Vérifiez par vous-mêmes si votre avocat est en mesure de vous vanter ses propres mérites et, par le fait même, de vous convaincre de l’utilité de ses services.</p>
<h2>Avec quels types d’accusations criminelles les avocats sont-ils en mesure de vous aider?</h2>
<p>Les accusations d’ordre criminelles et pénales sont lourdes de conséquences, bien plus que les causes civiles. Une importante compensation monétaire semblera bien peu comparée à un dossier criminel durant toute une vie, croyez-nous! C’est là l’importance d’engager un avocat dès que le potentiel de poursuite criminelle se pointe le bout du nez. Pour avoir une meilleure idée de ce à quoi vous vous exposez, voici les types d’accusations qui pourraient être portées contre vous.</p>
<p><StaticImage alt="categories-accusations-criminelles-aide-avocats-criminalistes-sherbrooke" src="../images/categories-accusations-criminelles-aide-avocats-criminalistes-sherbrooke.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>
L’infraction sommaire </strong>: Il s’agit des infractions les moins graves prévues par le Code criminel en raison des conséquences peu sévères qu’elles entraînent. Par exemple, le vol à l’étalage est normalement considéré comme une infraction sommaire lorsque la valeur de la marchandise volée est faible. La procédure pour une infraction sommaire est moins longue que pour un acte criminel.</p>
<p>Aucune enquête préliminaire n’est effectuée et c’est un juge seul qui se charge d’entendre la cause et de rendre le verdict de culpabilité ou de non-culpabilité.  Il est parfois même possible d’envoyer un avocat pour se présenter en cour à votre place à moins que le juge n’exige la présence physique de l’accusé.</p>
<p><strong>L’acte criminel : </strong>Les gestes et les comportements les plus graves sont considérés comme des actes criminels en vertu du Code de ce même nom. L’exemple le plus facile est évidemment le meurtre; geste immoral et inacceptable que personne ne peut commettre en société à moins d’agir en légitime défense.</p>
<p>Lorsque vous êtes accusé d’un crime grave, vous avez droit qu’une enquête préliminaire est effectuée pour déterminer la suffisance de la preuve accumulée. Par la suite, si le juge ordonne la continuation du procès, celui-ci se déroulera devant un juge et un jury. Les infractions criminelles moins graves permettent cependant le procès devant juge seul.</p>
<p><strong>L’infraction mixte : </strong>On considérera une infraction mixte lorsque le geste répréhensible se situe dans une zone grise du droit. Cela signifie qu’il pourrait tout aussi bien être considéré comme un acte criminel que comme un acte sommaire; ce sera au procureur de la couronne de décider sous quel titre il déposera les accusations.</p>
<p><strong>Contravention à un règlement :</strong> Les contraventions ne sont pas inscrites au Code criminel, car elles les comportements visés ne brisent pas des lois, mais bien des règlements. Ceux-ci ont une valeur inférieure aux lois et leur transgression mène donc à une contravention et non à une accusation criminelle.</p>
<h2>Pour comparer les avocats à Sherbrooke et en Estrie, confiez votre mandat à Soumissions Avocat!</h2>
<p>Ne perdez pas votre temps à contacter tous les avocats du bottin téléphonique. Si vous êtes rendus à l’étape d’engager un avocat, laissez Soumissions Avocats se charger de comparer les experts pour vous. Nous faisons affaire avec les meilleurs juristes en Estrie pour vous fournir des services juridiques de qualité.</p>
<p><strong>Tout ce qu’il vous reste à faire, c’est de remplir le formulaire au bas de la page et nous vous acheminerons 3 soumissions gratuites d’avocats à Sherbrooke!</strong></p>
<p><strong>Rien ne sert d’attendre, nos services sont gratuits et sans engagement!</strong></p>
    </SeoPage>
)
export default ContentPage17
  